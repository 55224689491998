<template>
  <div class="more-div">
      <div class="title">{{title}}</div>
      <div class="btn" v-if="showBtn">
          <Button type="primary" size="small" @click="click">{{btnTitle}}</Button>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:()=>{
                return ''
            }
        },
        btnTitle:{
            type:String,
            default:()=>{
                return '更多数据详情'
            }
        },
        showBtn:{
            type:Boolean,
            default:()=>{
                return true
            }
        }
    },
    methods:{
        click(){
            this.$emit('click')
        }
    }
}
</script>

<style lang="less" scoped>
.more-div{
    display: flex;
    // margin: 20px 0 ;
    border-bottom: 1px dashed #ccc;
    padding: 20px 0;
    .title{
        font-size: 16px;
        font-weight: 700;
        padding: 0 20px;
    }
}
</style>